<template>
  <div id="all-page">
    <defaultTemplate>
      <!-- api subject -->

      <div class="pd-x-5 pd-y-3">
        <p>Semester : 1/2020</p>

        <div class=" is-flex js-center  ai-center">
          <p class=" font-18 mg-b-3 mg-t-4 color-00 font-weight-500">
            {{ "Learning hour 160 / " }}
          </p>
          <p class=" font-26 mg-b-3 mg-t-5 color-00 font-weight-500">
            {{ " 180  hour" }}
          </p>
        </div>
        <div class="text-center">
          <CirclePrecess
            :percentage="83"
            :color="`#353535`"
            :strokewidth="15"
            :width="190"
          />
        </div>
        <p class="text-center font-12 mg-t-3 text-light font-weight-500">
          Last updated : {{ updateAt | timeNormal }}
        </p>
        <p
          v-if="Subjects"
          class="font-14 text-dark pd-t-5 pd-x-5   pd-y-5 border-b"
        >
          {{ "Subject : " + Subjects.length }}
        </p>

        <div class="pd-t-7" v-for="(subject, index) in Subjects" :key="index">
          <!-- <pre>
            {{ subject }}
          </pre> -->
          <a :href="`/subject/detail/${subject._id}`" class="cursor-pointer">
            <CardProgress
              :nowHour="160"
              :totalHour="180"
              :percentNow="50"
              :bgProgress="`light`"
              :nameSubject="subject.name"
              :countSubject="6"
            />
          </a>
        </div>
      </div>

      <!-- <div class="pd-x-5 pd-y-3">
        <p>Semester : {{ Subjects.namesemester }}</p>

        <div class=" is-flex js-center  ai-center">
          <p class=" font-18 mg-b-3 mg-t-4 color-00 font-weight-500">
            {{ "Learning hour " + getHourSemester(Subjects) + "/ " }}
          </p>
          <p class=" font-26 mg-b-3 mg-t-5 color-00 font-weight-500">
            {{ " " + Subjects.hour + "  hour" }}
          </p>
        </div>
        <div class="text-center" v-if="Subjects">
          <CirclePrecess
            :percentage="perCent(getHourSemester(Subjects), Subjects.hour)"
            :color="`#353535`"
            :strokewidth="15"
            :width="190"
          />
        </div>
        <p class="text-center font-12 mg-t-3 text-light font-weight-500">
          Last updated : {{ updateAt | timeNormal }}
        </p>
        <p
          v-if="Subjects"
          class="font-14 text-dark pd-t-5 pd-x-5   pd-y-5 border-b"
        >
          {{ "Subject : " + Subjects.subject.length }}
        </p>

        <div
          class="pd-t-7"
          v-for="(data, index) in Subjects.subject"
          :key="'sj' + index"
        >
          <a
            :href="`/subject/detail/${SemesterId}/${data.id}`"
            class="cursor-pointer"
          >
            <CardProgress
              :nowHour="getNowHour(data)"
              :totalHour="data.hour"
              :percentNow="perCent(getNowHour(data), data.hour)"
              :bgProgress="`light`"
              :nameSubject="data.namesubject"
              :countSubject="6"
            />
          </a>
        </div>
      </div> -->
    </defaultTemplate>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import Data from "@/db/data.json";
import CirclePrecess from "@/components/circleProcess.vue";
import CardProgress from "@/components/CardProgress.vue";
import { HTTP } from "@/service/axios";
export default {
  components: {
    defaultTemplate,
    CardProgress,
    Data,
    CirclePrecess,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.getSubject();
  },
  data() {
    return {
      updateAt: new Date(),
      data: Data,
      SemesterId: this.$route.params.id,
      learningId: this.$route.params.id,
      Subjects: "",
    };
  },
  methods: {
    // filter เทอมตาม ไอดีจะได้ subject มา
    getSubject() {
      // console.log(this.$route.params.id);
      HTTP.defaults.headers.common.Authorization = `Bearer ` + this.user.token;
      HTTP.get(`learn/semester/${this.$route.params.id}`)
        .then((res) => {
          if (res.data.success) {
            this.Subjects = res.data.obj;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // let subJect = this.data.user.semester.filter(
      //   (item) => this.$route.params.id == item.id
      // )[0];
      // this.Subjects = typeof subJect !== "undefined" ? subJect : [];
    },

    getHour(badge) {
      let getHour = 0;
      let totalHour = 0;
      // console.log(badge, "badgebadge");
      badge.map((bg) => {
        // console.log(bg, "bg");
        // console.log(bg.hour, "bg.hour");
        bg.detail.map((detail) => {
          // console.log(detail, "detail");
          getHour = detail.learningHour + getHour;
        });
      });
      totalHour = getHour + totalHour;
      return totalHour;
    },

    getHourTotal(badge) {
      let totalHourL = 0;
      badge.map((tt) => {
        totalHourL = tt.totalHour + totalHourL;
      });

      // console.log(totalHourL, "totalHourL");
      return totalHourL;
    },

    perCent(hour, hourTotal) {
      let percentNow = (hour * 100) / hourTotal;
      return Number(percentNow.toFixed(0));
    },

    learnungHour() {
      let toTalhour = 0;
      this.semester.subject.map((bj) => {
        toTalhour = this.getHour(bj.badge) + toTalhour;
      });
      // console.log(toTalhour, "toTalhour");
      return toTalhour;
    },
    toTallearnungHour() {
      let toTalhour = 0;
      this.semester.subject.map((bj) => {
        toTalhour = this.getHourTotal(bj.badge) + toTalhour;
      });
      // console.log(toTalhour, "toTalhour");
      return toTalhour;
    },

    getHourSemester(data) {
      if (this.Subjects != "") {
        let sunsemester = 0;
        data.subject.forEach((sj) => {
          let sum = this.getNowHour(sj);
          sunsemester = sum + sunsemester;
        });
        return sunsemester;
      }
    },

    getNowHour(data) {
      if (this.Subjects != "") {
        let nowhour = 0;
        data.badges.forEach((his) => {
          his.history.forEach((lh) => {
            nowhour = nowhour + lh.learnninghour;
          });
        });
        return nowhour;
      }
    },
  },
};
</script>

<style></style>
